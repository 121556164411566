import Text from 'components/atoms/Text';
import IncidentSection1 from 'components/organisms/IncidentSection1';
import IncidentSection2 from 'components/organisms/IncidentSection2';
import IncidentSection3 from 'components/organisms/IncidentSection3';
import IncidentSection4 from 'components/organisms/IncidentSection4';
import IncidentSection5 from 'components/organisms/IncidentSection5';
import IncidentSection6 from 'components/organisms/IncidentSection6';
import React, { useCallback, useEffect, useState, createRef } from 'react';
import Button from 'components/atoms/Button';
import { useGetIncidentMutation } from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IQueryReportSection,
	ISection1ErrorStates,
	ISection2ErrorStates,
	ISection3ErrorStates,
	ISection4ErrorStates,
	IVehicleIncidentSection1,
	IVehicleIncidentSection2,
	IVehicleIncidentSection3,
	IVehicleIncidentSection4,
	bodyParts,
	bodyPartsInjured,
	incidentAgency,
	incidentMechanism,
	injuryType,
	ISection5ErrorStates,
	IVehicleIncidentSection5,
} from 'types/VehicleIncidents';
import { XIcon } from '@heroicons/react/outline';
import { useGetIncidentSection1 } from 'utils/useGetIncidentSection1';
import { useGetIncidentSection2 } from 'utils/useGetIncidentSection2';
import { useGetIncidentSection3 } from 'utils/useGetIncidentSection3';
import { useGetIncidentSection4 } from 'utils/useGetIncidentSection4';
import { useSetErrorState } from 'utils/useSetErrorState';
import IncidentSection7 from '../IncidentSection7';
import TabLinkIncidentPanel from '../../atoms/TabLinkIncidentPanel';
import { useGetIncidentSection5 } from '../../../utils/useGetIncidentSection5';
import { TailSpin } from 'react-loader-spinner';

export interface IIncidentPanel {
	getIncidents: (filters: string, prepend: boolean) => void;
	filters: string;
	onClose: () => void;
}

export interface IncidentDetails {
	name: string;
	isRead: boolean;
}

const IncidentPanel: React.FC<IIncidentPanel> = ({
	getIncidents,
	filters,
	onClose,
}) => {
	const [section, setSectionRaw] = useState<IQueryReportSection>('section1');
	const setSection = (section: IQueryReportSection) => {
		setSectionRaw(section);
		scrollDivRef.current?.scrollIntoView();
	};
	const [savingIncident, setSavingIncident] = useState<boolean>(false);
	const {
		incidentSection1Id,
		incidentSection2Id,
		incidentSection3Id,
		incidentSection4Id,
		incidentSection5Id,
		incidentSection6Id,
		incidentSection7Id,
		incidentReportId,
		isNewIncident,
		iCamIsVisible,
	} = useAppSelector((state) => state.vehicleIncident);

	const [getIncident, { data, isSuccess }] = useGetIncidentMutation();
	const [readTabs, setReadTabs] = useState([
		{ name: '1. Description', isRead: true },
		{ name: '2. Person Involved', isRead: false },
		{ name: '3. Injury', isRead: false },
		{ name: '4. Analysis', isRead: false },
		{ name: '5. Investigation', isRead: false },
		{ name: '6. ICAM', isRead: false },
		{ name: '7. Corrective Actions', isRead: false },
	]);

	const [iCamIsVisibleState, setICamIsVisibleState] = useState(
		iCamIsVisible || false
	);

	useEffect(() => {
		if (!isNewIncident && incidentReportId !== null) {
			getIncident(incidentReportId);
		}
	}, [isNewIncident, incidentReportId, getIncident]);

	const [errorTabStates, setErrorTabStates] = useState({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
		section6: false,
		section7: false,
	});

	const [errorMessages, setErrorMessages] = useState<string[]>([]);

	const scrollDivRef = createRef<HTMLDivElement>();

	const [incidentSection1, setIncidentSection1] =
		useState<IVehicleIncidentSection1>({
			description: '',
			location: '',
			client: '',
			jobDetails: '',
			altusRegistration: '',
			driverName: '',
			licenseClass: '',
			licenseExpiry: '',
			incident_date: undefined,
			reported_date: new Date(),
			branch: '',
			incident_time: '',
			reported_time: '',
			mv_incident: false,
			thirdParty: false,
			thirdParties: [
				{
					id: 1,
					thirdParty_name: '',
					thirdParty_vehicle_registration: '',
					thirdParty_licence_number: '',
					thirdParty_phone_number: '',
				},
			],
			actionTaken: '',
			emergencyResponseAttendance: [{ id: 0, title: 'Test', checked: false }],
			emergencyResponseAttendanceOther: '',
			trafficControllersIntervened: false,
			workCeasedUnsafeConditions: false,
		});

	const [incidentSection2, setIncidentSection2] =
		useState<IVehicleIncidentSection2>({
			id: null,
			personInvolved: [
				{
					id: 1,
					personInvolved_name: '',
					personInvolved_phone_number: '',
					personInvolved_start_date: undefined,
					personInvolved_length_of_service: '',
					personInvolved_task: '',
				},
			],
			witness_exists: false,
			witnesses: [
				{
					id: 1,
					witness_name: '',
					witness_position: '',
					witness_task: '',
					witness_phone_number: '',
				},
			],
		});

	const [incidentSection3, setIncidentSection3] =
		useState<IVehicleIncidentSection3>({
			bodyParts,
			bodyPartsInjured,
			bodyPartsInjuredOtherInput: '',
			injuryType,
			injuryTypeOtherInput: '',
			incidentMechanism,
			incidentMechanismOtherInput: '',
			incidentAgency,
			incidentAgencyOtherInput: '',
			injuries: false,
			restricted_injury: false,
			mviCausation: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
		});

	const [incidentSection4, setIncidentSection4] =
		useState<IVehicleIncidentSection4>({
			severityRating: '1',
			reportedTo: '',
			referenceNumber: '',
			notes: '',
			incidentCategory: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
			incidentMechanism,
			incidentMechanismOtherInput: '',
			incidentAgency,
			incidentAgencyOtherInput: '',
			lifeSavingRules: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
			codeOfConduct: [
				{
					id: 1,
					title: '',
					checked: false,
				},
			],
			isNotifiableIncident: false,
			isLifeSavingBreach: false,
			isCodeOfConductBreach: false,
			mviCausation: [
				{
					id: 1,
					title: '',
					checked: false,
					description: '',
				},
			],
		});

	const [incidentSection5, setIncidentSection5] =
		useState<IVehicleIncidentSection5>({
			id: 0,
			section1Id: 0,
			incidentReportId: 0,
			shift_start_time: '',
			incident_investigation_required: false,
			percentage_of_shift_worked: '',
			investigator: '',
			users: [
				{
					id: '1',
					label: '',
				},
			],
			attachments: [],
		});

	const [section1ErrorStates, setSection1IncidentErrorStates] =
		useState<ISection1ErrorStates>({
			section_1_incident_description: false,
			section_1_location: false,
			section_1_client_name: false,
			section_1_job_details: false,
			section_1_incident_date: false,
			section_1_reported_date: false,
			section_1_incident_time: false,
			section_1_reported_time: false,
		});

	const [section2ErrorStates, setSection2IncidentErrorStates] =
		useState<ISection2ErrorStates>({});

	const [section3ErrorStates, setSection3IncidentErrorStates] =
		useState<ISection3ErrorStates>({
			section_3_body_parts: false,
			section_3_injury_type: false,
			section_3_incident_mechanism: false,
			section_3_incident_agency: false,
		});

	const [section4ErrorStates, setSection4IncidentErrorStates] =
		useState<ISection4ErrorStates>({
			section_4_incident_category: false,
			section_4_reference_number: false,
			section_4_reported_to: false,
			section_4_life_saving_rules: false,
			section_4_code_of_conduct_checkbox: false,
			section_4_incident_agency: false,
			section_4_incident_mechanism: false,
		});

	const [section5ErrorStates, setSection5IncidentErrorStates] =
		useState<ISection5ErrorStates>({
			section_5_shift_start_time: false,
			section_5_investigator: false,
		});

	useGetIncidentSection1(
		isSuccess,
		data,
		setIncidentSection1,
		setSection1IncidentErrorStates
	);

	useGetIncidentSection2(
		isSuccess,
		data,
		setIncidentSection2,
		setSection2IncidentErrorStates
	);

	useGetIncidentSection3(
		isSuccess,
		data,
		setIncidentSection3,
		setSection3IncidentErrorStates
	);

	useGetIncidentSection4(
		isSuccess,
		data,
		setIncidentSection4,
		setSection4IncidentErrorStates,
		incidentSection3.injuries
	);

	useGetIncidentSection5(
		isSuccess,
		data,
		setIncidentSection5,
		setSection5IncidentErrorStates
	);

	useSetErrorState(section1ErrorStates, 'section1', setErrorTabStates);
	useSetErrorState(section2ErrorStates, 'section2', setErrorTabStates);
	useSetErrorState(section3ErrorStates, 'section3', setErrorTabStates);
	useSetErrorState(section4ErrorStates, 'section4', setErrorTabStates);
	useSetErrorState(section5ErrorStates, 'section5', setErrorTabStates);

	const validateSections = useCallback(
		(
			section:
				| ISection2ErrorStates
				| ISection1ErrorStates
				| ISection3ErrorStates
				| ISection4ErrorStates
				| ISection5ErrorStates
		) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (!value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[]
	);

	const validateSection1 = useCallback(
		(section: ISection1ErrorStates) => {
			if (!incidentSection1.thirdParty) {
				return validateSections(section);
			} else {
				const section1Errors = validateSections(section);
				incidentSection1?.thirdParties?.forEach((thirdParty, index) => {
					if (!thirdParty.thirdParty_name) {
						section1Errors.push(`section 1 third party ${index + 1} name`);
					}
					if (!thirdParty.thirdParty_vehicle_registration) {
						section1Errors.push(
							`section 1 third party ${index + 1} vehicle registration`
						);
					}
					if (!thirdParty.thirdParty_licence_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} licence number`
						);
					}
					if (!thirdParty.thirdParty_phone_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} phone number`
						);
					}
				});

				return section1Errors;
			}
		},
		[
			incidentSection1.thirdParty,
			incidentSection1.thirdParties,
			validateSections,
		]
	);

	const validateSection2 = useCallback(
		(section: ISection2ErrorStates) => {
			if (
				!incidentSection2.witness_exists &&
				incidentSection2?.personInvolved?.length <= 0
			) {
				return validateSections(section);
			} else {
				const section2Errors = validateSections(section);
				if (incidentSection2.witness_exists) {
					incidentSection2.witnesses.forEach((witness, index) => {
						if (!witness.witness_name) {
							section2Errors.push(`section 2 witness ${index + 1} name`);
						}
						if (!witness.witness_phone_number) {
							section2Errors.push(
								`section 2 witness ${index + 1} phone number`
							);
						}
					});
				}
				if (incidentSection2?.personInvolved?.length > 0) {
					incidentSection2.personInvolved.forEach((person, index) => {
						if (!person.personInvolved_name) {
							section2Errors.push(
								`section 2 person involved ${index + 1} name`
							);
						}
						if (!person.personInvolved_phone_number) {
							section2Errors.push(
								`section 2 person involved ${index + 1} phone number`
							);
						}
					});
				}
				return section2Errors;
			}
		},
		[
			incidentSection2.witness_exists,
			incidentSection2.witnesses,
			incidentSection2.personInvolved,
			validateSections,
		]
	);

	const validateSection3 = useCallback(
		(section: ISection3ErrorStates) => {
			if (incidentSection3.injuries) {
				return validateSections(section);
			} else {
				return [];
			}
		},
		[incidentSection3.injuries, validateSections]
	);

	const validateSection4 = useCallback(
		(section: ISection4ErrorStates) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (
					(key === 'section_4_reference_number' ||
						key === 'section_4_reported_to') &&
					incidentSection4.isNotifiableIncident &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_life_saving_rules' &&
					incidentSection4.isLifeSavingBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_code_of_conduct_checkbox' &&
					incidentSection4.isCodeOfConductBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_agency' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_mechanism' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_4_incident_category' && !value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[
			incidentSection4.isNotifiableIncident,
			incidentSection4.isLifeSavingBreach,
			incidentSection4.isCodeOfConductBreach,
			incidentSection3.injuries,
		]
	);

	const validateSection5 = useCallback(
		(section: ISection5ErrorStates) => {
			const validateInvestigator = () => {
				if (incidentSection5.incident_investigation_required) {
					if (incidentSection5.investigator) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}
			};

			const validateShiftStartTime = () => {
				if (incidentSection5.shift_start_time) {
					return true;
				} else {
					return false;
				}
			};

			setSection5IncidentErrorStates({
				section_5_investigator: validateInvestigator(),
				section_5_shift_start_time: validateShiftStartTime(),
			});

			const section5ErrorArray: string[] = [];

			if (incidentSection5.incident_investigation_required) {
				return validateSections(section);
			}

			for (const [key, value] of Object.entries(section)) {
				if (
					key === 'section_5_investigator' &&
					!value &&
					incidentSection5.incident_investigation_required
				) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_5_shift_start_time' && !value) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key !== 'section_5_shift_start_time' &&
					key !== 'section_5_investigator' &&
					!value
				) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return section5ErrorArray;
		},
		[
			incidentSection5.investigator,
			incidentSection5.incident_investigation_required,
			incidentSection5.shift_start_time,
			validateSections,
		]
	);

	const handleValidate = () => {
		validateSections(section1ErrorStates);
		const section1Messages = validateSection1(section1ErrorStates);
		const section2Messages = validateSection2(section2ErrorStates);
		const section3Messages = validateSection3(section3ErrorStates);
		const section4Messages = validateSection4(section4ErrorStates);
		const section5Messages = validateSection5(section5ErrorStates);

		setErrorMessages([
			...section1Messages,
			...section2Messages,
			...section3Messages,
			...section4Messages,
			...section5Messages,
		]);

		setErrorTabStates({
			section1: section1Messages.length !== 0,
			section2: section2Messages.length !== 0,
			section3: section3Messages.length !== 0,
			section4: section4Messages.length !== 0,
			section5: section5Messages.length !== 0,
			section6: false,
			section7: true,
		});
	};

	return (
		<>
			{savingIncident && (
				<div className="z-[9999] px-[20px] py-[8px] fixed right-[20px] bottom-[20px] w-[200px] bg-white flex flex-row justify-center border-solid border-[1px] border-secondary-500 rounded-xl">
					<div className="h-[40px] flex flex-col justify-center mr-[20px]  font-inter text-base font-bold tracking-normal text-secondary-900">
						Saving...
					</div>
					<div className="flex flex-col justify-center h-full">
						<TailSpin
							height="25"
							width="25"
							ariaLabel="tail-spin-loading"
							color="#FE9F00"
							wrapperClass="flex mt-2"
							radius={2}
							visible
						/>
					</div>
				</div>
			)}
			<div className="xl:w-[calc(100vw-7rem)] w-[100%]">
				<div className="sticky top-[10px] z-[100] bg-primary-50 pt-[30px] border-b-secondary-200 border-b-[1px]">
					<div className="flex justify-between mb-[30px] px-[20px]">
						<Text
							type="bold"
							className="text-lg py-[6px] px-[15px] border-[2px] border-gray-300 rounded"
							color="text-gray-700"
						>
							Incident Report #:{' '}
							<span className="text-black text-xl">{incidentReportId}</span>
						</Text>
						<div className="flex flex-col justify-center text-right cursor-pointer">
							<Button
								type={'quaternary'}
								className="!rounded-3xl px-[20px] py=[5px] tracking-[1.92px]"
								onClick={() => {
									setReadTabs([
										{ name: '1. Description', isRead: true },
										{ name: '2. Person Involved', isRead: false },
										{ name: '3. Injury', isRead: false },
										{ name: '4. Analysis', isRead: false },
										{ name: '5. Investigation', isRead: false },
										{ name: '6. ICAM', isRead: false },
										{ name: '7. Corrective Actions', isRead: false },
									]);
									onClose();
								}}
							>
								<span className="mr-[5px]">CANCEL</span>
								<XIcon height={18} width={18} />
							</Button>
						</div>
					</div>
					<div className="flex justify-between px-[20px]">
						<div className="flex gap-[5px]">
							<TabLinkIncidentPanel
								active={section === 'section1'}
								onClick={() => {
									let temp = readTabs;
									temp[0].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section1');
								}}
								errorExists={errorTabStates.section1}
								type={'incidentPanel'}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								1. Description
							</TabLinkIncidentPanel>
							<TabLinkIncidentPanel
								active={section === 'section2'}
								onClick={() => {
									let temp = readTabs;
									temp[1].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section2');
								}}
								errorExists={errorTabStates.section2}
								type={'incidentPanel'}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								2. Person Involved
							</TabLinkIncidentPanel>
							<TabLinkIncidentPanel
								active={section === 'section3'}
								onClick={() => {
									let temp = readTabs;
									temp[2].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section3');
								}}
								errorExists={errorTabStates.section3}
								type={'incidentPanel'}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								3. Injury
							</TabLinkIncidentPanel>
							<TabLinkIncidentPanel
								active={section === 'section4'}
								onClick={() => {
									let temp = readTabs;
									temp[3].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section4');
								}}
								errorExists={errorTabStates.section4}
								type={'incidentPanel'}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								4. Analysis
							</TabLinkIncidentPanel>
							<TabLinkIncidentPanel
								active={section === 'section5'}
								onClick={() => {
									let temp = readTabs;
									temp[4].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section5');
								}}
								errorExists={errorTabStates.section5}
								type={'incidentPanel'}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								5. Investigation
							</TabLinkIncidentPanel>
							{iCamIsVisibleState && (
								<TabLinkIncidentPanel
									active={section === 'section6'}
									onClick={() => {
										let temp = readTabs;
										temp[5].isRead = true;
										handleValidate();
										setReadTabs(temp);
										setSection('section6');
									}}
									errorExists={errorTabStates.section6}
									type={'incidentPanel'}
									readTabs={readTabs}
								>
									6. ICAM
								</TabLinkIncidentPanel>
							)}
							<TabLinkIncidentPanel
								active={section === 'section7'}
								onClick={() => {
									handleValidate();
									setReadTabs([
										{ name: '1. Description', isRead: true },
										{ name: '2. Person Involved', isRead: true },
										{ name: '3. Injury', isRead: true },
										{ name: '4. Analysis', isRead: true },
										{ name: '5. Investigation', isRead: true },
										{ name: '6. ICAM', isRead: false },
										{ name: '7. Corrective Actions', isRead: false },
									]);
									setSection('section7');
									scrollDivRef.current?.scrollIntoView();
								}}
								type={'incidentPanel'}
								errorExists={false}
								readTabs={readTabs}
								disabled={savingIncident}
							>
								Corrective Actions
							</TabLinkIncidentPanel>
						</div>
					</div>
				</div>
				<div className="p-[20px]" ref={scrollDivRef}>
					{section === 'section1' && (
						<IncidentSection1
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							sectionId={incidentSection1Id}
							incidentSection1={incidentSection1}
							setIncidentSection1={setIncidentSection1}
							setSection1IncidentErrorStates={setSection1IncidentErrorStates}
							setSection={setSection}
							handleValidate={handleValidate}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
						/>
					)}
					{section === 'section2' && (
						<IncidentSection2
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							sectionId={incidentSection2Id}
							incidentSection2={incidentSection2}
							setIncidentSection2={setIncidentSection2}
							setSection2IncidentErrorStates={setSection2IncidentErrorStates}
							setSection={setSection}
							handleValidate={handleValidate}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
						/>
					)}
					{section === 'section3' && (
						<IncidentSection3
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							sectionId={incidentSection3Id}
							incidentSection3={incidentSection3}
							setIncidentSection3={setIncidentSection3}
							setSection3IncidentErrorStates={setSection3IncidentErrorStates}
							setSection={setSection}
							handleValidate={handleValidate}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
						/>
					)}
					{section === 'section4' && (
						<IncidentSection4
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							sectionId={incidentSection4Id}
							incidentSection4={incidentSection4}
							setIncidentSection4={setIncidentSection4}
							setSection4IncidentErrorStates={setSection4IncidentErrorStates}
							incidentSection3Injuries={incidentSection3.injuries}
							setSection={setSection}
							handleValidate={handleValidate}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
						/>
					)}
					{section === 'section5' && (
						<IncidentSection5
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							setSection5IncidentErrorStates={setSection5IncidentErrorStates}
							setIncidentSection5={setIncidentSection5}
							sectionId={incidentSection5Id}
							setSection={setSection}
							// setErrorTabStates={setErrorTabStates}
							handleValidate={handleValidate}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
							setICamIsVisible={setICamIsVisibleState}
						/>
					)}
					{section === 'section6' && iCamIsVisibleState && (
						<IncidentSection6
							sectionId={incidentSection6Id}
							setSection={setSection}
							handleValidate={handleValidate}
						/>
					)}
					{section === 'section7' && (
						<IncidentSection7
							readTabs={readTabs}
							setReadTabs={setReadTabs}
							sectionId={incidentSection7Id}
							errorMessages={errorMessages}
							setSection={setSection}
							handleValidate={handleValidate}
							getIncidents={getIncidents}
							filters={filters}
							completed={data?.incidentData.completed || false}
							savingIncident={savingIncident}
							setSavingIncident={setSavingIncident}
							iCamIsVisible={iCamIsVisibleState}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default IncidentPanel;
