import { ClockIcon, PlusIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import ComboBox from 'components/atoms/ComboBox';
import DateInput from 'components/atoms/DateInput';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import {
	IOption,
	ISection1Type,
	IVehicleIncidentSection1,
	IVehicleIncidentSection1Payload,
	ISection1ErrorStates,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import ThirdParty from 'components/molecules/ThirdParty';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection1Mutation,
	useUpdateIncidentSection1Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { removeSeconds } from 'utils/removeSeconds';
import FormCheckBoxGroupV2 from '../../molecules/FormCheckboxGroupV2';
import Divider from '../../atoms/Divider';
import LoaderSpinner from '../../atoms/LoaderSpinner';

export interface IIncidentSection1 {
	sectionId: number | null;
	incidentSection1: IVehicleIncidentSection1;
	setIncidentSection1: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection1>
	>;
	setSection1IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection1ErrorStates>
	>;
	setSection: (section: IQueryReportSection) => void;
	handleValidate: () => void;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
}

interface IThirdParty {
	id: number;
	thirdParty_name: string;
	thirdParty_vehicle_registration: string;
	thirdParty_licence_number: string;
	thirdParty_phone_number: string;
}

const IncidentSection1: React.FC<IIncidentSection1> = ({
	sectionId,
	incidentSection1,
	setIncidentSection1,
	setSection1IncidentErrorStates,
	setSection,
	handleValidate,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
}) => {
	const [initialSetSection, setInitialSetSection] = useState<boolean>(false);
	const [updateIncidentSection1, { isLoading: saving }] =
		useUpdateIncidentSection1Mutation();
	const [getIncidentSection1, { data, isLoading, isSuccess }] =
		useGetIncidentSection1Mutation();
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [section1, setSection1] =
		useState<IVehicleIncidentSection1>(incidentSection1);
	const [toggle, setToggle] = useState(section1.mv_incident);
	const [thirdPartyToggle, setThirdPartyToggle] = useState(section1.thirdParty);
	const [trafficControllerToggle, setTrafficControllerToggle] = useState(
		section1.trafficControllersIntervened
	);
	const [workCeasedToggle, setWorkCeasedToggle] = useState(
		section1.workCeasedUnsafeConditions
	);

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	const disableIncidentWriteAccess = IncidentState.isNewIncident
		? false
		: disableWriteAccess(
				'incident_level',
				userPermission,
				IncidentState.incidentBranchId
		  );
	const dedupeBranches: { [key: string]: boolean } = {};
	const branchOptions = userPermission
		? userPermission
				.map((permission) => {
					return {
						id: permission.branch.id.toString(),
						label: permission.branch.name,
					};
				})
				.sort((a, b) => {
					return a.label > b.label ? 1 : -1;
				})
				.filter((item) => {
					// @ts-ignore
					return dedupeBranches.hasOwnProperty(item.id)
						? false
						: (dedupeBranches[item.id] = true);
				})
		: [
				{
					id: '',
					label: '',
				},
		  ];

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection1(sectionId);
		}
		//disables dependency for "section 1" and IncidentState.isNewIncident
		// eslint-disable-next-line
	}, [getIncidentSection1, sectionId]);

	const handleCheckboxUpdate = (
		property: string,
		value: IOption[] | string
	) => {
		if (property === 'emergencyResponseAttendance') {
			handleSection1Update(property, value);
		}
	};

	const handleSection1Update = (
		property: ISection1Type,
		value: Date | string | IOption[],
		index?: number,
		disableUpdateRequest?: boolean
	) => {
		const newSection1: IVehicleIncidentSection1 = section1;
		const newThirdParties = [...(newSection1.thirdParties || [])];

		if (property === 'incident_date' || property === 'reported_date') {
			if (typeof value === 'object') {
				// @ts-ignore
				newSection1[property] = value;
			}
		} else if (
			property === 'thirdParty_name' ||
			property === 'thirdParty_vehicle_registration' ||
			property === 'thirdParty_licence_number' ||
			property === 'thirdParty_phone_number'
		) {
			if (typeof index !== 'undefined') {
				newThirdParties[index] = {
					...newThirdParties[index],
					[property]: value,
				};
			}
		} else {
			// @ts-ignore
			newSection1[property] = value;
		}

		if (!disableUpdateRequest) {
			handleOnBlur();
		}

		newSection1.thirdParties = newThirdParties;
		setIncidentSection1(newSection1);
	};

	useEffect(() => {
		if (data && initialSetSection) {
			handleOnBlur();
		} else if (data) {
			setInitialSetSection(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggle, thirdPartyToggle, trafficControllerToggle, workCeasedToggle]);

	useEffect(() => {
		setToggle(section1.mv_incident);
	}, [section1.mv_incident]);

	const dataHasBeenChanged = (data: any): boolean => {
		if (
			data?.incident_description ||
			data?.action_taken ||
			data?.client_name ||
			data?.job_details ||
			data?.location ||
			data?.driverName ||
			data?.licenseClass ||
			data?.licenseExpiry ||
			data?.altusRegistration ||
			data?.emergencyResponseAttendanceOther ||
			data?.reported_time
		)
			return true;

		return false;
	};

	useEffect(() => {
		if (isSuccess && data) {
			const {
				branch,
				incident_date,
				reported_date,
				reported_time,
				incident_time,
				mv_incident,
				incident_description,
				location,
				client_name,
				job_details,
				altusRegistration,
				driverName,
				licenseClass,
				licenseExpiry,
				thirdParty,
				thirdParties,
				emergencyResponseAttendance,
				trafficControllersIntervened,
				workCeasedUnsafeConditions,
				emergencyResponseAttendanceOther,
				actionTaken,
			} = data?.data;

			const formatIncidentDate = incident_date
				? new Date(incident_date)
				: undefined;
			const formatReportedDate = new Date(reported_date);

			const formatTime = removeSeconds(incident_time);
			const useReportedTime = dataHasBeenChanged(data.data)
				? reported_time
				: new Date().toTimeString().slice(0, 5);
			const formatTimeReportedTime = removeSeconds(useReportedTime);

			const formatThirdParty = thirdParties?.map((thirdParty: IThirdParty) => {
				return {
					...thirdParty,
				};
			});

			setSection1({
				...section1,
				description: incident_description || '',
				location: location || '',
				client: client_name || '',
				jobDetails: job_details || '',
				altusRegistration: altusRegistration || '',
				driverName: driverName || '',
				licenseClass: licenseClass || '',
				licenseExpiry: licenseExpiry || '',
				branch: branch || '',
				incident_date: formatIncidentDate,
				reported_date: formatReportedDate,
				incident_time: formatTime || '',
				reported_time: formatTimeReportedTime || '',
				actionTaken: actionTaken || '',
				thirdParty,
				thirdParties:
					typeof thirdParties !== 'undefined' && thirdParties.length > 0
						? formatThirdParty
						: section1.thirdParties,
				emergencyResponseAttendance: emergencyResponseAttendance,
				emergencyResponseAttendanceOther: emergencyResponseAttendanceOther,
			});
			setToggle(mv_incident);
			setThirdPartyToggle(thirdParty);
			setTrafficControllerToggle(trafficControllersIntervened);
			setWorkCeasedToggle(workCeasedUnsafeConditions);
		}
		//removes dependency for "section 1"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}
		const formatIncidentTime =
			section1.incident_time && section1.incident_time.length < 6
				? `${section1.incident_time}:00.000`
				: section1.incident_time;

		const formatReportedTime =
			section1.reported_time && section1.reported_time.length < 6
				? `${section1.reported_time}:00.000`
				: section1.reported_time;

		let branchName = '';
		branchOptions.forEach((item) => {
			if (item.id === section1.branch) {
				branchName = item.label;
			}
		});

		if (branchName === '') {
			branchName = section1.branch;
		}

		const formattedThirdParties = section1?.thirdParties?.map((thirdParty) => {
			return {
				...thirdParty,
			};
		});

		const payload: IVehicleIncidentSection1Payload = {
			id: sectionId,
			incidentId: IncidentState.incidentReportId,
			branch: branchName,
			incident_date: section1.incident_date
				? section1.incident_date.toString()
				: undefined,
			reported_date: section1.reported_date
				? section1.reported_date.toString()
				: '',
			incident_time: formatIncidentTime,
			reported_time: formatReportedTime,
			location: section1.location,
			incident_description: section1.description,
			client_name: section1.client,
			job_details: section1.jobDetails,
			mv_incident: toggle,
			altusRegistration: section1.altusRegistration,
			driverName: section1.driverName,
			licenseClass: section1.licenseClass,
			licenseExpiry: section1.licenseExpiry,
			thirdParty: thirdPartyToggle,
			thirdParties: thirdPartyToggle ? formattedThirdParties : [],
			actionTaken: section1.actionTaken,
			emergencyResponseAttendance:
				section1.emergencyResponseAttendance as IOption[],
			emergencyResponseAttendanceOther:
				section1.emergencyResponseAttendanceOther,
			trafficControllersIntervened: trafficControllerToggle,
			workCeasedUnsafeConditions: workCeasedToggle,
		};

		await updateIncidentSection1(payload);

		setSection1IncidentErrorStates({
			section_1_incident_description: section1.description !== '',
			section_1_location: section1.location !== '',
			section_1_client_name: section1.client !== '',
			section_1_job_details: section1.jobDetails !== '',
			section_1_incident_date: section1.incident_date ? true : false,
			section_1_reported_date: section1.reported_date ? true : false,
			section_1_incident_time: section1.incident_time !== '',
			section_1_reported_time: section1.reported_time !== '',
		});
	};

	const updatePayload = async (thirdParties: IThirdParty[]) => {
		const formattedThirdParties = thirdParties.map(
			(thirdParty: IThirdParty) => {
				return {
					...thirdParty,
				};
			}
		);

		if (!sectionId) {
			return;
		}

		const formatIncidentTime =
			section1.incident_time && section1.incident_time.length < 6
				? `${section1.incident_time}:00.000`
				: section1.incident_time;

		const formatReportedTime =
			section1.reported_time && section1.reported_time.length < 6
				? `${section1.reported_time}:00.000`
				: section1.reported_time;

		let branchName = '';
		branchOptions.forEach((item) => {
			if (item.id === section1.branch) {
				branchName = item.label;
			}
		});

		if (branchName === '') {
			branchName = section1.branch;
		}

		const payload: IVehicleIncidentSection1Payload = {
			id: sectionId,
			incidentId: IncidentState.incidentReportId,
			branch: branchName,
			incident_date: section1.incident_date
				? section1.incident_date.toString()
				: '',
			reported_date: section1.reported_date
				? section1.reported_date.toString()
				: '',
			incident_time: formatIncidentTime,
			reported_time: formatReportedTime,
			location: section1.location,
			incident_description: section1.description,
			client_name: section1.client,
			job_details: section1.jobDetails,
			mv_incident: toggle,
			altusRegistration: section1.altusRegistration,
			driverName: section1.driverName,
			licenseClass: section1.licenseClass,
			licenseExpiry: section1.licenseExpiry,
			thirdParty: thirdPartyToggle,
			thirdParties: formattedThirdParties,
			actionTaken: section1.actionTaken,
			emergencyResponseAttendance:
				section1.emergencyResponseAttendance as IOption[],
			emergencyResponseAttendanceOther:
				section1.emergencyResponseAttendanceOther,
			trafficControllersIntervened: trafficControllerToggle,
			workCeasedUnsafeConditions: section1.workCeasedUnsafeConditions,
		};

		await updateIncidentSection1(payload);
	};

	const addThirdParty = async () => {
		if (disableIncidentWriteAccess) return;
		const { thirdParties } = section1;
		let assignId = 1;
		if (thirdParties && thirdParties.length > 0) {
			assignId = thirdParties[thirdParties.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addThirdParty = [
			...(section1.thirdParties || []),
			{
				id: assignId,
				thirdParty_name: '',
				thirdParty_vehicle_registration: '',
				thirdParty_licence_number: '',
				thirdParty_phone_number: '',
			},
		];

		setSection1((section1) => {
			return {
				...section1,
				thirdParties: addThirdParty,
			};
		});

		updatePayload(addThirdParty);
	};

	const deleteThirdParty = async (id: number) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section1?.thirdParties?.filter(
			(thirdParty) => thirdParty.id !== id
		);
		setSection1((prevSection1) => {
			return {
				...prevSection1,
				thirdParties: filteredList,
			};
		});

		updatePayload(filteredList || []);
	};

	return (
		<>
			{isLoading || !data ? (
				<>
					<LoaderSpinner />
				</>
			) : (
				<>
					<Heading
						type="h1"
						className="uppercase mb-[64px] mt-[20px]"
						colour={'secondary'}
					>
						Incident Description
					</Heading>
					<form>
						<div className="flex flex-wrap gap-8">
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update(
											'incident_date',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									className=""
									label="Incident Date"
									required={true}
									iconClassName="text-black"
									selected={section1.incident_date}
									wrapperClassName="react-datepicker-margin-0"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/5 grow">
								<Input
									type="time"
									onChange={(value: string) =>
										handleSection1Update(
											'incident_time',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									value={section1.incident_time}
									className="mb-10"
									required={true}
									label="Incident Time"
									Icon={ClockIcon}
									iconClassName="text-black"
									iconPosition="trailing"
									placeholder="Select Time"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/5 grow">
								<DateInput
									placeholder="Select Date"
									onChange={(value: Date) =>
										handleSection1Update(
											'reported_date',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									className="mb-10"
									label="Reported Date"
									required={true}
									iconClassName="text-black"
									selected={section1.reported_date}
									wrapperClassName="react-datepicker-margin-0"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/5 grow">
								<Input
									type="time"
									onChange={(value: string) =>
										handleSection1Update(
											'reported_time',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									value={section1.reported_time}
									className="mb-10"
									required={true}
									label="Reported Time"
									Icon={ClockIcon}
									iconClassName="text-black"
									iconPosition="trailing"
									placeholder="Select Time"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						</div>
						<div className="w-1/4 grow pr-[24px]">
							<ComboBox
								label="Branch"
								options={branchOptions}
								isDisabled={disableIncidentWriteAccess}
								required={true}
								onChange={(value: string) =>
									handleSection1Update('branch', value)
								}
								selected={section1.branch}
							/>
						</div>
						<div>
							<TextareaInput
								value={section1.description}
								label="Incident Description"
								required={true}
								resize={true}
								rows={10}
								cols={37}
								placeholder="Clearly state what happened in the incident, including the outcome and sequence of events leading up to the incident."
								onChange={(value: string) =>
									handleSection1Update('description', value, undefined, true)
								}
								onBlur={handleOnBlur}
								isDisabled={disableIncidentWriteAccess}
							/>
						</div>
						<div>
							<TextareaInput
								value={section1.actionTaken}
								label="Action Taken"
								required={true}
								resize={true}
								rows={10}
								cols={37}
								placeholder="Description"
								onChange={(value: string) =>
									handleSection1Update('actionTaken', value, undefined, true)
								}
								onBlur={handleOnBlur}
								isDisabled={disableIncidentWriteAccess}
							/>
						</div>
						<div className="basis-1/4 grow mt-4 w-[25%] w-min-[400px] mb-[24px]">
							<Input
								type="text"
								onChange={(value: string) =>
									handleSection1Update('client', value, undefined, true)
								}
								onBlur={handleOnBlur}
								value={section1.client}
								className=""
								required={true}
								label="Client Name"
								placeholder="Name"
								isDisabled={disableIncidentWriteAccess}
							/>
						</div>
						<div className="flex flex-wrap gap-8 mt-4">
							<div className="basis-1/4 grow">
								<TextareaInput
									value={section1.location}
									label="Exact Location"
									required={true}
									rows={10}
									cols={37}
									placeholder="Location where the incident happened. (Location, Address or area)"
									resize={true}
									onChange={(value: string) =>
										handleSection1Update('location', value, undefined, true)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="basis-1/4 grow">
								<TextareaInput
									onChange={(value: string) =>
										handleSection1Update('jobDetails', value, undefined, true)
									}
									onBlur={handleOnBlur}
									value={section1.jobDetails}
									label="Job Details"
									required={true}
									rows={10}
									cols={37}
									placeholder="Clearly state Client/Job details"
									resize={true}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						</div>
						<div className="flex flex-wrap gap-8 mt-4">
							<div className="basis-1/4 grow">
								<Heading type="h2" className="uppercase mb-[32px]">
									Emergency Response Attendance
								</Heading>
								<FormCheckBoxGroupV2
									checkBoxName="emergencyResponseAttendance"
									checkBoxGroupData={section1.emergencyResponseAttendance}
									onHandleChange={handleCheckboxUpdate}
									gridColumns={'4'}
									otherTextBox={true}
									otherTextBoxValue={section1.emergencyResponseAttendanceOther}
									otherTextBoxOnChange={(value: string) =>
										handleSection1Update(
											'emergencyResponseAttendanceOther',
											value
										)
									}
									handleOnBlur={handleOnBlur}
									otherTextBoxClassName=""
									isDisabled={disableIncidentWriteAccess}
									otherTextBoxPlaceholder={'Other'}
								/>
							</div>
						</div>
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<ToggleButtonV2
							toggle={trafficControllerToggle}
							setToggle={setTrafficControllerToggle}
							label="Traffic controllers intervened due to unsafe conditions"
							isDisabled={disableIncidentWriteAccess}
							margins={'0'}
						/>
						<div className="mb-[32px]" />
						<ToggleButtonV2
							toggle={workCeasedToggle}
							setToggle={setWorkCeasedToggle}
							label="Work ceased due to unsafe conditions"
							isDisabled={disableIncidentWriteAccess}
						/>
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<ToggleButtonV2
							toggle={toggle}
							setToggle={setToggle}
							label="Would you like to enter details of an MVI or MVI3 incident? *"
							isDisabled={disableIncidentWriteAccess}
						/>
						{toggle && (
							<div className="flex flex-wrap gap-8 my-6">
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update(
											'altusRegistration',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									value={section1.altusRegistration}
									className="basis-1/5 grow"
									label="Altus Registration"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('driverName', value, undefined, true)
									}
									onBlur={handleOnBlur}
									value={section1.driverName}
									className="basis-1/5 grow"
									label="Name of driver"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update('licenseClass', value, undefined, true)
									}
									onBlur={handleOnBlur}
									value={section1.licenseClass}
									className="basis-1/5 grow"
									label="Licence # & Class"
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									onChange={(value: string) =>
										handleSection1Update(
											'licenseExpiry',
											value,
											undefined,
											true
										)
									}
									onBlur={handleOnBlur}
									value={section1.licenseExpiry}
									className="basis-1/5 grow"
									label="Licence Expiry"
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						)}
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>

						{/* Third Party */}
						<ToggleButtonV2
							toggle={thirdPartyToggle}
							setToggle={setThirdPartyToggle}
							label="Is there a third party involved?"
							isDisabled={disableIncidentWriteAccess}
						/>
						{thirdPartyToggle && (
							<>
								{section1?.thirdParties &&
									section1?.thirdParties?.length > 0 &&
									section1?.thirdParties?.map((thirdParty, index) => {
										const { id } = thirdParty;
										return (
											<ThirdParty
												key={id}
												handleSection1Update={handleSection1Update}
												onBlur={handleOnBlur}
												deleteThirdParty={deleteThirdParty}
												id={id}
												thirdParty={thirdParty}
												index={index}
												isDisabled={disableIncidentWriteAccess}
											/>
										);
									})}
								<Button
									onClick={addThirdParty}
									type="secondary"
									className="font-bold mr-4 !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
									isDisabled={disableIncidentWriteAccess}
								>
									<PlusIcon height={18} width={18} className="mr-2" />
									Add another third party
								</Button>
							</>
						)}
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<div className="flex justify-end mb-[64px]">
							<Button
								onClick={() => {
									let temp = readTabs;
									temp[0].isRead = true;
									handleValidate();
									setReadTabs(temp);
									setSection('section2');
								}}
								type="primary"
								isDisabled={savingIncident}
								className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
							>
								Continue to Person Involved
							</Button>
						</div>
					</form>
				</>
			)}
		</>
	);
};

export default IncidentSection1;
