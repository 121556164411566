import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFilters, initialFilterState, IOption } from 'types/VehicleIncidents';

export interface VehicleIncidentState {
	commentsOpen: boolean;
	commentIncidentId: number | null;
	incidentBranchId: string | null;
	incidentReportOpen: boolean;
	incidentReportId: number | null;
	incidentSection1Id: number | null;
	incidentSection2Id: number | null;
	incidentSection3Id: number | null;
	incidentSection4Id: number | null;
	incidentSection5Id: number | null;
	incidentSection6Id: number | null;
	incidentSection7Id: number | null;
	isNewIncident: boolean;
	filters: IFilters;
	action: 'view' | null;
	basicPermissionsBranches: IOption[];
	basicPermissionsStates: IOption[];
	iCamIsVisible?: boolean;
}

export type ActionPayload = 'view' | null;

export interface CommentPayload {
	commentIncidentId: number | null;
	incidentBranchId: string | null;
}

export interface VehicleIncidentReportState {
	incidentReportId: number | null;
	incidentBranchId?: string | null;
	incidentSection1Id?: number | null;
	incidentSection2Id?: number | null;
	incidentSection3Id?: number | null;
	incidentSection4Id?: number | null;
	incidentSection5Id?: number | null;
	incidentSection6Id?: number | null;
	incidentSection7Id?: number | null;
	isNewIncident?: boolean;
	iCamIsVisible?: boolean;
}

const initialState: VehicleIncidentState = {
	commentsOpen: false,
	commentIncidentId: null,
	incidentBranchId: null,
	incidentReportOpen: false,
	incidentReportId: null,
	isNewIncident: false,
	incidentSection1Id: null,
	incidentSection2Id: null,
	incidentSection3Id: null,
	incidentSection4Id: null,
	incidentSection5Id: null,
	incidentSection6Id: null,
	incidentSection7Id: null,
	filters: initialFilterState,
	action: null,
	basicPermissionsBranches: [],
	basicPermissionsStates: [],
	iCamIsVisible: false,
};

const vehicleIncidentSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		openCommentModal(state, action: PayloadAction<CommentPayload>) {
			const payload = action.payload;
			state.commentIncidentId = payload.commentIncidentId;
			state.incidentBranchId = payload.incidentBranchId;
			state.commentsOpen = true;
		},
		closeCommentModal(state) {
			state.commentIncidentId = null;
			state.incidentBranchId = null;
			state.commentsOpen = false;
		},
		openIncidentModal(
			state,
			action: PayloadAction<VehicleIncidentReportState>
		) {
			const payload = action.payload;
			state.incidentReportId = payload?.incidentReportId || null;
			state.incidentReportOpen = true;
			state.incidentBranchId = payload.incidentBranchId?.toString() || null;
			state.incidentSection1Id = payload?.incidentSection1Id || null;
			state.incidentSection2Id = payload?.incidentSection2Id || null;
			state.incidentSection3Id = payload?.incidentSection3Id || null;
			state.incidentSection4Id = payload?.incidentSection4Id || null;
			state.incidentSection5Id = payload?.incidentSection5Id || null;
			state.incidentSection6Id = payload?.incidentSection6Id || null;
			state.incidentSection7Id = payload?.incidentSection7Id || null;
			state.isNewIncident = payload?.isNewIncident || false;
			state.iCamIsVisible = payload?.iCamIsVisible || false;
		},
		updateIncidentSectionId(
			state,
			action: PayloadAction<VehicleIncidentReportState>
		) {
			const payload = action.payload;
			state.incidentReportId = payload?.incidentReportId || null;
			state.incidentBranchId = payload?.incidentBranchId || null;
			state.incidentSection1Id = payload?.incidentSection1Id || null;
			state.incidentSection2Id = payload?.incidentSection2Id || null;
			state.incidentSection3Id = payload?.incidentSection3Id || null;
			state.incidentSection4Id = payload?.incidentSection4Id || null;
			state.incidentSection5Id = payload?.incidentSection5Id || null;
			state.incidentSection6Id = payload?.incidentSection6Id || null;
			state.incidentSection7Id = payload?.incidentSection7Id || null;
			state.iCamIsVisible = payload?.iCamIsVisible || false;
			state.isNewIncident = payload?.isNewIncident || false;
		},
		closeIncidentModal(state) {
			state.incidentReportId = null;
			state.incidentBranchId = null;
			state.incidentReportOpen = false;
			state.isNewIncident = false;
		},
		updateIncidentFilterState(state, action: PayloadAction<IFilters>) {
			state.filters = action.payload;
		},
		resetIncidentFilterState(state) {
			state.filters = initialFilterState;
		},
		updateIncidentActionState(state, action: PayloadAction<ActionPayload>) {
			state.action = action.payload;
		},
		updateBasicPermissions(
			state,
			action: PayloadAction<{ branch: IOption[]; state: IOption[] }>
		) {
			state.basicPermissionsBranches = action.payload.branch;
			state.basicPermissionsStates = action.payload.state;
		},
	},
});

export const {
	openCommentModal,
	closeCommentModal,
	openIncidentModal,
	closeIncidentModal,
	updateIncidentFilterState,
	updateIncidentSectionId,
	resetIncidentFilterState,
	updateIncidentActionState,
	updateBasicPermissions,
} = vehicleIncidentSlice.actions;
export default vehicleIncidentSlice.reducer;
